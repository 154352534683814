.container{
    max-width: 1300px !important;
    margin: 0 auto;
    position: relative;
}
*{
    user-select: none;
    /* cursor: none; */

}
@media (max-width:1300px){
    .container{
        padding: 0 1.5rem;
    }
}