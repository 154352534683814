.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.project-card {
  background: rgba(22, 17, 47, 0.398);
  border-radius: 0.65rem;
  overflow: hidden;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  padding: 1.5rem;
  text-align: center;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.project-content h6 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ac96ff;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.view-more {
  display: inline-block;
  margin-bottom: 1rem;
  color: #7c5fe6;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.view-more:hover {
  color: #aa94fe;
}

.project-links {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.live-link, .github-link {
  color: #7c5fe6;
  text-decoration: none;
  font-weight: 500;
}

.live-link:hover, .github-link:hover {
  color: #aa94fe;
}

.disabled-link {
  font-size: 0.9rem;
  color: #6757b9;
  font-style: italic;
}

@media (max-width: 768px) {
  .project-card {
    padding: 1rem;
  }
  .project-thumbnail {
    height: 150px;
  }
}
