/* General Wrapper for the entire page */
.project-detail-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title Styling */
.project-detail-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #aba6a6;
  text-align: center;
  margin-bottom: 1rem;
}

.project-detail-description {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: center;
  color: #d0c8c8;
  margin-bottom: 2rem;
}

/* Tools & Technologies */
.project-tools {
  margin-bottom: 2rem;
}

.project-tools-heading {
  font-size: 1.5rem;
  color: #8d8d8d;
  margin-bottom: 1rem;
  /* text-align: center; */
}

.project-tools-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.project-tool {
  background-color: #f3f3f3;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: 1rem;
  color: #4a4a4a;
}

/* Links for GitHub and Live Demo */
.project-links {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.project-link-btn {
  background-color: #7c66e3;
  padding: 0.75rem 1.5rem;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.project-link-btn:hover {
  background-color: #5a49c7;
}

.project-private-info {
  font-size: 1rem;
  color: #ff5c5c;
  text-align: center;
}

/* Image Slider */
.project-image-slider {
  width: 100%;
  max-width: 900px;
}

.project-image-slide {
  padding: 1%;
}

.project-image {
  width: 100%;
  height: 70vh;
  border-radius: 10px;
  object-fit: contain;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .project-detail-title {
    font-size: 2rem;
  }

  .project-detail-description {
    font-size: 1rem;
  }
.project-image-slider{
  width: 700px;
}
  .project-tools-list {
    flex-direction: column;
  }

  .project-links {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .project-detail-title {
    font-size: 1.8rem;
  }

  .project-detail-description {
    font-size: 1rem;
  }

  .project-link-btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .project-detail-wrapper {
    padding: 1rem;
  }

  .project-detail-title {
    font-size: 1.5rem;
  }

  .project-detail-description {
    font-size: 0.9rem;
  }

  .project-tools-heading {
    font-size: 1.2rem;
  }
  .project-image-slider{
    width: 300px;
  }
  .project-link-btn{
    width: 100%;
  }
}
