@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Flamenco:wght@300;400&display=swap');

*{
    font-family: 'Poppins','Courier New', Courier, monospace;
    margin: 0;
    box-sizing: border-box ;
    scroll-behavior: smooth;
}

body {
  color: #fff;
  background-color: #060417;
}
/* Custom scrollbar for webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #2a2252; /* Track background */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #7c66e3; /* Color of the scrolling thumb */
  border-radius: 10px;
  border: 2px solid #2a2252; /* Padding around thumb */
}

/* Scrollbar for Firefox */
* {
  scroll-behavior: smooth;
  scrollbar-width:thin; 
  scrollbar-color: #7c66e3 #2a2252; 
}

html{
  scroll-padding-top: 85px;
}