
.hero-container {
  display: flex;
  align-items: center;
  position: relative;
}

.hero-container::after,
.hero-container::before {
  content: " ";
  width: 28.125rem;
  height: 28.125rem;
  border-radius: 28.125rem;
  background: #7c66e3;
  z-index: -1;
  position: absolute;
  filter: blur(220px);
}

.hero-container::after {
  top: -3rem;
  left: -5rem;
}
.hero-container::before {
  bottom: 2rem;
  right: 0rem;
}
.hero-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hero-content h2 {
  font-size: 3.3rem;
  font-weight: 700;
  line-height: 5rem;
  margin-bottom: .7rem;
  font-family:  Flamenco,Italiana,'Bodoni Moda';
}
.hero-content p {
  width: 80%;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  font-family:  "Cutive MOno",Italiana,'Bodoni Moda';
}
.typed-roles {
  font-size: 2.5rem;
}

.hero-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 5rem;
}

.hero-img > div {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}
.hero-img > div > img {
  border-radius: 20%;
  width: 25rem;
  transition: all 0.3s ease;
  /* animation: profileshape 3s infinite; */
}

.tech-icon {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 0.65rem;
  border: 1.5px solid #6852ba;
}

.tech-icon img {
  width: 3rem;
  height: auto;
  transition: all 0.3s ease;
  /* animation: profileshape 3s infinite; */
}

.hero-img > div > img:hover,
.tech-icon img:hover {
  /* animation: profileshape 3s infinite; */
  transform: translateY(-0.5rem);
  cursor:none;
}
/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 20px;
}

.social-icon {
  font-size: 35px;
  color: #473882;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #7b67d3; 
  transform: scale(1.2);
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  font-family:'Courier New', Courier, monospace;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.hero-contact-btn {
  background: linear-gradient(180deg, #aa94fe 0%, #7c5fe6 100%);
  color: #fff;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, transform 0.3s ease, border 0.3s ease;
}

.hero-contact-btn:hover {
  background: transparent;
  /* transform: scale(1.05); */
  border-color: #aa94fe;
}

.resume-btn {
  background-color: #5f61627b;
  color: #fff;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, transform 0.3s ease, border 0.3s ease;
}

.resume-btn:hover {
  background: transparent;
  /* transform: scale(1.05); */
  border: 2px solid #5f6162bc;
}

@keyframes profileshape {
  0% {
    border-radius: 400px 500px 600px 700px;
  }
  40% {
    border-radius: 700px 400px 500px 600px;
  }
  50% {
    border-radius: 600px 700px 400px 500px;
  }

  100% {
    border-radius: 500px 600px 700px 400px;
  }
}

@media (max-width: 1025px) {
  .hero-content h2 {
    font-size: 3rem;
  }
  .hero-content p {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }

  .hero-img > div > img {
    width: 20rem;
  }

  .tech-icon {
    height: 4rem;
    width: 4rem;
  }
  .tech-icon img {
    width: 2.5rem;
  }
  .btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column-reverse;
  }
  .hero-img {
    margin: 2rem 0 2rem 0;
  }

  .hero-content h2 {
    margin-top: 3rem;
  }
  .hero-content h2,
  .hero-content p {
    width: auto;
    text-align: center;
  }

  .hero-container::after,
  .hero-container::before {
    content: " ";
    width: 18rem;
    height: 18rem;
  }
  .hero-container::after {
    top: 0rem;
    left: 0rem;
  }

  .hero-container::before {
    bottom: 2rem;
    right: 0rem;
  }
  .btn {
    font-size: 13px;
    padding: 7px 14px;
  }
}

@media (max-width: 600px) {
  .hero-container{
    flex-direction: column-reverse;

  }
  .hero-content {
    align-items: center;
  }
  .hero-content h2 {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  .hero-content p {
    font-size: 0.8rem;
    line-height: 1.3rem;
  }

  .hero-img {
    gap: 1rem;
  }
  .hero-img > div {
    gap: 1rem;
  }
  .hero-img > div > img {
    width: 16rem;
  }

  .tech-icon {
    width: 3.3em;
    height: 3.3rem;
  }

  .tech-icon img {
    width: 2rem;
  }
  .btn {
    font-size: 12px;
    padding: 6px 12px;
  }
  .typed-roles{
    font-size: 2rem;
  }
}
